<template>
  <div class="service-log">
    <div>
      <el-form inline>
        <el-form-item label="选择应用*" v-if="userInfo.username === 'admin'">
          <el-select v-model="form.appId" placeholder="请选择应用">
            <el-option v-for="item of tableData" :label="`${item.name}-${item._id}`" :key="item._id"
                       :value="item._id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="事件名称*">
          <el-select v-model="form.eventName" placeholder="请选择事件名称">
            <el-option v-for="item of eventList" :label="`${item.name}`" :key="item._id"
                       :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期*">
          <el-date-picker
              v-model="form.date"
              type="date"
              format="yyyy-MM-dd"
              placeholder="选择日志日期"
              value-format="yyyyMMdd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="用户">
          <el-input type="text" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="searchData"><i class="el-icon-search"></i> 查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table">
      <el-table
          :data="logList"
          border
          style="width: 100%">
        <el-table-column
            prop="_id"
            label="ID"
            width="230"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="用户"
            align="center"
        >
        </el-table-column>
        <el-table-column
            label="事件记录时间"
            prop="createTime"
            align="center"
            :formatter="row=>returnTime(row.createTime)"
        >
        </el-table-column>
        <el-table-column
            label="事件数据"
            prop="info"
            align="center"
        >
          <template slot-scope="{row}">
            <el-tooltip
                class="item"
                effect="dark"
                :content="JSON.stringify(row.gatherData)"
                placement="top"
                style="width: 100%"
            >
              <span
                  style="max-width: 100%; display: block; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">{{
                  JSON.stringify(row.gatherData)
                }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination" v-if="logCount>0">
        <el-pagination
            background
            layout="prev, pager, next"
            :total="logCount"
            @current-change="currentChange"
            :current-page="form.page"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import dateFun from "@/utils/dateFun"
import {appLogParams, getAppList, getEventData, getEventlist} from "@/api/base";

export default {
  name: "ServiceLog",
  data() {
    return {
      form: {
        page: 1,
        limit: 10
      },
      eventList: {},
      userInfo: {},
      tableData: [],
      logList: [],
      logCount: 0,
    }
  },
  mounted() {
    const userInfo = localStorage.getItem('userInfo')
    this.userInfo = JSON.parse(userInfo)
    this.getQueryParams()
    this.$set(this.form, 'date', this.getTimeStrByHour())
    this.getList()
    this.getAppList()
  },
  methods: {
    getAppList() {
      getAppList().then(res => {
        this.tableData = res.data
      })
    },
    getList() {
      getEventlist().then(res => {
        this.eventList = res.data
      })
    },
    getQueryParams() {
      appLogParams().then(res => {
        this.queryParams = res.data
      })
    },
    currentChange(current) {
      this.form.page = current
      this.search()
    },
    searchData() {
      this.$set(this.form, 'page', 1)
      this.search()
    },
    search() {
      if (!this.form.eventName) {
        this.$message({
          message: "请选择事件",
          type: "warning"
        })
        return
      }
      if (!this.form.date) {
        this.$message({
          message: "请选择事件日期",
          type: "warning"
        })
        return
      }
      getEventData(this.form).then(res => {
        this.logList = res.data
        this.logCount = res.count
      })
    },
    returnTime(time) {
      return dateFun.getCurrentDate(true, ['-', ':'], time)
    },
    getTimeStrByHour(time = null) {
      const today = time ? new Date(time) : new Date()
      const year = today.getFullYear()

      // 月份
      let month = today.getMonth() + 1
      if (month < 10) month = `0${month}`

      // 日期
      let day = today.getDate()
      if (day < 10) day = `0${day}`

      // 组装
      return `${year}${month}${day}`
    }
  }
}
</script>

<style scoped>
.table {
  margin-top: 10px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
<style>
.el-tooltip__popper {
  max-width: 500px;
}
</style>
