import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import router from "@/router"
// import store from '@/store'


const service = axios.create({
    baseURL: 'https://nodejs.pay3966.com/',
    timeout: 30000, // 请求超时时间，设置15秒。有些接口事务多能会长
})

// 请求发起拦截
service.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token")
        if(token) {
            config.headers.eventToken = token
        }
        return config
    },
    (error) => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    },
)

// 请求响应拦截
service.interceptors.response.use(
    (response) => {
        const res = response.data
        if (res.code !== 0) {
            if (res.code === 401) {
                MessageBox.confirm('登录信息失效，请重新登录', '确定登出', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    showCancelButton: false,
                    showClose:false,
                    closeOnClickModal:false,
                    type: 'warning',
                }).then(() => {
                    router.push('/')
                })
            } else if (res.code === 500) {
                Message({
                    message: '程序开小差了，请稍后再试！',
                    type: 'error',
                    duration: 5 * 1000,
                })
            } else {
                Message({
                    message: res.msg || 'Error',
                    type: 'error',
                    duration: 5 * 1000,
                })
            }
            return Promise.reject(res)
        } else {
            return res
        }
    },
    (error) => {
        if (error.response.status === 401) {
            MessageBox.confirm('登录信息失效，请重新登录', '确定登出', {
                confirmButtonText: '重新登录',
                cancelButtonText: '取消',
                showCancelButton: false,
                showClose:false,
                closeOnClickModal:false,
                type: 'warning',
            }).then(() => {
                router.push('/')
            })
        }
        let { message } = error
        if (message === 'Network Error') {
            message = '接口连接异常'
        } else if (message.includes('timeout')) {
            message = '系统接口请求超时'
        } else if (message.includes('Request failed with status code')) {
            message = '系统接口' + message.substr(message.length - 3) + '异常'
        }
        Message({
            message: message,
            type: 'error',
            duration: 5 * 1000,
        })
        return Promise.reject(error)
    },
)

export default service
