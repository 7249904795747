<template>
  <div class="user-events">
    <div class="btns">
      <el-button @click="addEvent" >添加应用事件</el-button>
    </div>
    <div class="table">
      <el-table :data="list" border>
        <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="事件名称"
            align="center"
        >
        </el-table-column>
        <el-table-column
            label="事件类型"
            prop="eventType"
            width="100"
            align="center"
        >
          <template slot-scope="{row}">
            {{filterType(row.eventType)}}
          </template>
        </el-table-column>
        <el-table-column
            prop="appId"
            label="应用ID"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="method"
            label="提交方式"
            align="center"
            width="100"
        >
        </el-table-column>
        <el-table-column
            prop="createDateTable"
            label="根据日期每日建表"
            align="center"
            width="80"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.createDateTable === 1" >是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="remarks"
            label="事件备注"
            align="center"
        >
        </el-table-column>
        <el-table-column
            label="事件采集字段"
            align="center"
            width="120"
        >
          <template slot-scope="{row}">
            <el-button size="mini" @click="viewGatherData(row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            align="center"
            :formatter="(row)=>dateFun.getCurrentDate(true, ['-', ':'], row.createTime)"
        >
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态"
            align="center"
            width="80"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status === 1" >正常</span>
            <span v-else>停用</span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            align="center"
        >
          <template slot-scope="{row}">
            <el-button size="mini" type="primary" @click="editRow(row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deleteRow(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  查看事件采集字段  -->
    <el-dialog title="查看事件采集字段" :visible.sync="dialogTableVisible">
      <el-table :data="gridData" border>
        <el-table-column property="name" label="字段名"></el-table-column>
        <el-table-column property="required" label="是否必填">
          <template slot-scope="{row}">
            {{row.required || row.required==='true'?'是':'否'}}
          </template>
        </el-table-column>
        <el-table-column property="remarks" label="备注"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 添加应用事件   -->
    <div class="add-form">
      <el-dialog
          :title="`${addForm._id?'编辑':'添加'}应用事件`"
          :visible.sync="dialogVisible"
          width="650px"
          :close-on-click-modal="false"
      >
        <el-form :model="addForm" :inline="true">
          <el-row>
            <el-form-item label="事件名称">
              <el-input v-model="addForm.name" class="input"></el-input>
            </el-form-item>
            <el-form-item label="事件提交方式" label-width="170px">
              <el-checkbox-group v-model="addForm.method" >
                <el-checkbox label="socket"></el-checkbox>
                <el-checkbox label="http"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="事件类型">
              <el-select v-model="addForm.eventType" style="width: 202px">
                <el-option v-for="(item,index) of eventTypeList" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="根据日期创建数据表" label-width="170px">
              <el-radio-group v-model="addForm.createDateTable">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="状态" v-if="addForm._id">
              <el-radio-group v-model="addForm.status">
                <el-radio :label="1">启用</el-radio>
                <el-radio :label="0">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="事件备注">
              <el-input v-model="addForm.remarks" class="input"></el-input>
            </el-form-item>
          </el-row>
          <span style="padding-bottom: 10px; display: block; font-weight: bold; border-bottom: 1px solid #ebeaea">事件收集字段</span>
          <el-row align="middle" justify="center" style="border-bottom: 1px solid #ebeaea; padding: 10px 0" v-for="(item,index) of addForm.gatherData" :key="index">
            <el-col :span="6">
              <el-input v-model="addForm.gatherData[index].name" placeholder="事件名称-必填" size="mini"></el-input>
            </el-col>
            <el-col :span="6" style="height: 28px; display: flex; align-items: center; justify-content: center; margin-right: 10px">
              必填：
              <el-radio-group v-model="addForm.gatherData[index].required" >
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-col>
            <el-col :span="6">
              <el-input v-model="addForm.gatherData[index].remarks" placeholder="备注" size="mini"></el-input>
            </el-col>
            <el-col :span="5" style="padding-left: 10px">
              <el-button @click="addRow" v-if="index === 0" size="mini" type="success">新增一行</el-button>
              <el-button @click="delRow(index)" v-if="index !== 0" size="mini" type="warning">删除该行</el-button>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
    <el-button type="primary" @click="submitEvent" size="mini">确 定</el-button>
  </span>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import dateFun from "@/utils/dateFun";
import {createEvent, deleteEvent, getEventlist, updateEvent} from "@/api/base";

export default {
  name: "userEvents",
  data(){
    return {
      list:[],
      dialogTableVisible:false,
      gridData:[],
      dialogVisible:false,
      addForm:{
        method:['socket','http'],
        createDateTable:0,
        gatherData:[{
          name:'',
          remarks:'',
          required:true
        }]
      },
      gatherData:{
        name:'',
        remarks:'',
        required:true
      },
      userInfo:{},
      eventTypeList:[
        {label:"点击事件", value: 1},
        {label:"曝光事件", value: 2},
        {label:"停留事件", value: 3},
        {label:"通用事件", value: 4},
      ],
      dateFun
    }
  },
  mounted() {
    this.getList()
    const userInfo = localStorage.getItem('userInfo')
    this.userInfo = JSON.parse(userInfo)
  },
  methods:{
    filterType(type){
      switch (type){
        case 1:
          return "点击事件"
        case 2:
          return "曝光事件"
        case 3:
          return "停留事件"
        case 4:
          return "通用事件"
        default:
          return '-'
      }
    },
    addEvent(){
      if(this.userInfo.username === 'admin'){
        this.$message({
          message:"admin账户不能操作",
          type:"error"
        })
        return
      }
      this.addForm = {
        method:['socket','http'],
        createDateTable:0,
        gatherData:[{
          name:'',
          remarks:'',
          required:true
        }]
      }
      this.dialogVisible = true
    },
    getList(){
      getEventlist().then(res=>{
        this.list = res.data
      })
    },
    viewGatherData(row){
      console.log(row)
      this.gridData = row.gatherData
      this.dialogTableVisible = true
    },
    addRow(){
      this.addForm.gatherData.push(Object.assign({},this.gatherData))
    },
    delRow(index){
      this.addForm.gatherData.splice(index,1)
    },
    submitEvent(){
      if(!this.addForm.name){
        this.$message({
          message:"请填写事件名称",
          type:"warning"
        })
        return
      }
      let check = true
      for (let i = 0; i < this.addForm.gatherData.length; i++) {
        if(!this.addForm.gatherData[i].name){
          check = false
          break
        }
      }
      if(!check){
        this.$message({
          message:"事件收集字段填写不完整",
          type:"warning"
        })
        return
      }
      const cloneForm = JSON.parse(JSON.stringify(this.addForm))
      cloneForm.method = cloneForm.method.join(',')
      console.log(cloneForm)
      if(cloneForm._id){
        updateEvent(cloneForm).then(res=>{
          if(res.code === 0){
            this.$message({
              message:'编辑成功',
              type: 'success'
            });
            this.getList()
          }else{
            this.$message({
              message:res.msg,
              type: 'warning'
            });
          }
          this.dialogVisible = false
        })
      }else{
        createEvent(cloneForm).then(res=>{
          console.log(res)
          if(res.code === 0){
            this.$message({
              message:"新增成功",
              type:"success"
            })
            this.dialogVisible = false
            this.getList()
          }else{
            this.$message({
              message:res.msg,
              type:"error"
            })
          }
        })
      }

    },
    editRow(row){
      if(this.userInfo.username === 'admin'){
        this.$message({
          message:"admin账户不能操作",
          type:"error"
        })
        return
      }
      const cloneRow = JSON.parse(JSON.stringify(row))
      cloneRow.method = cloneRow.method.split(',')
      this.addForm = cloneRow
      this.dialogVisible = true
    },
    deleteRow(row){
      if(this.userInfo.username === 'admin'){
        this.$message({
          message:"admin账户不能操作",
          type:"error"
        })
        return
      }
      this.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteEvent({_id: row._id}).then(res=>{
          if(res.code === 0){
            this.$message({
              message:'删除成功',
              type: 'success'
            });
            this.getList()
          }else{
            this.$message({
              message:'删除失败',
              type: 'warning'
            });
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
.btns {
  margin-bottom: 20px;
}

</style>
