<template>
  <div class="login">
      <div class="login-form">
        <p>数据分析</p>
        <el-form :inline="false">
          <el-form-item label="用户名">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item label="验证码" >
            <el-input v-model="form.captcha"></el-input>
            <span class="captcha" @click="getCaptcha" v-html="svg"></span>
          </el-form-item>
          <el-form-item label=" ">
            <el-button class="login-btn" @click="login">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
  </div>
</template>

<script>
import {getCaptcha, login} from "@/api/base";

export default {
  name: "login",
  data(){
    return {
      form:{},
      svg:'',
      status:false,
    }
  },
  mounted() {
    this.getCaptcha()
  },
  methods:{
    getCaptcha(){
      if(this.status) return
      this.status = true
      getCaptcha().then(res=>{
        this.status = false
        this.svg = res.data.svg
        this.form.captchaKey = res.data.captchaKey
        this.$set(this.form,'captcha','')
      }).catch(()=>{
        this.status = false
      })
    },
    login(){
      if(!this.form.username){
        this.$message({
          message: '请输入用户名',
          type: 'warning'
        });
      }
      if(!this.form.password){
        this.$message({
          message: '请输入密码',
          type: 'warning'
        });
      }
      if(!this.form.captcha){
        this.$message({
          message: '请输入验证码',
          type: 'warning'
        });
      }
      login(this.form).then(res=>{
        if(res.code !== 0){
          this.$message({
            message: res.msg,
            type: 'warning'
          });
          return
        }
        this.$message({
          message:'登录成功',
          type: 'success'
        });
        localStorage.setItem('token', res.token)
        localStorage.setItem('userInfo', JSON.stringify(res.data))
        localStorage.setItem('ossBaseUrl', res.ossBaseUrl)
        this.$router.push('/home')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-form{
  width: 500px;
  background: #a7c3b7;
  margin-top: -15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding-bottom: 20px;
  p{
    font-size: 30px;
  }
  .login-btn{
    width: 100%;
  }
  .captcha{
    display: block;
    cursor: pointer;
    margin-top: 5px;
  }
}
</style>
