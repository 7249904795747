import service from "@/utils/ajax"

export const getCaptcha = (params)=>{
   return service({
       url:"/v1/captcha/getCaptcha",
       method:"get",
       params
   })
}

export const login = (data)=>{
    return service({
        url:"/v1/adminUser/login",
        method:"post",
        data
    })
}

export const getAppList = (params)=>{
    return service({
        url:"/v1/application/getAppList",
        method:"get",
        params
    })
}

export const deleteApp = (data)=>{
    return service({
        url:"/v1/application/deleteApp",
        method:"post",
        data
    })
}

export const updateStatus = (data)=>{
    return service({
        url:"/v1/application/updateStatus",
        method:"post",
        data
    })
}

export const createApp = (data)=>{
    return service({
        url:"/v1/application/createApp",
        method:"post",
        data
    })
}

export const getUserlist = (params)=>{
    return service({
        url:"/v1/adminUser/list",
        method:"get",
        params
    })
}

export const createUser = (data)=>{
    return service({
        url:"/v1/adminUser/create",
        method:"post",
        data
    })
}

export const updateUser = (data)=>{
    return service({
        url:"/v1/adminUser/update",
        method:"post",
        data
    })
}

export const createEvent = (data)=>{
    return service({
        url:"/v1/userEvent/createEvent",
        method:"post",
        data
    })
}

export const getEventlist = (params)=>{
    return service({
        url:"/v1/userEvent/getList",
        method:"get",
        params
    })
}

export const updateEvent = (data)=>{
    return service({
        url:"/v1/userEvent/updateEvent",
        method:"post",
        data
    })
}

export const deleteEvent = (data)=>{
    return service({
        url:"/v1/userEvent/deleteEvent",
        method:"post",
        data
    })
}

export const getLogList = (params)=>{
    return service({
        url:"/v1/manageLog/getLogList",
        method:"get",
        params
    })
}

export const appLogParams = (params)=>{
    return service({
        url:"/v1/manageLog/appLogParams",
        method:"get",
        params
    })
}

export const getEventData = (params)=>{
    return service({
        url:"/v1/userEvent/getEventData",
        method:"get",
        params
    })
}

export const abnormalLogList = (params)=>{
    return service({
        url:"/v1/abnormalLog/list",
        method:"get",
        params
    })
}
