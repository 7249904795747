<template>
  <div class="service-log">
    <div>
      <el-form inline>
        <el-form-item label="应用" v-if="userInfo.username === 'admin'">
          <el-select v-model="form.appId" placeholder="请选择应用" size="mini" clearable>
            <el-option v-for="item of tableData" :label="`${item.name}-${item._id}`" :key="item._id"
                       :value="item._id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户名称">
          <el-input type="text" v-model="form.name" size="mini" clearable></el-input>
        </el-form-item>
        <el-form-item label="设备品牌">
          <el-input type="text" v-model="form.deviceBrand" size="mini" clearable></el-input>
        </el-form-item>
        <el-form-item label="操作系统名称">
          <el-input type="text" v-model="form.osName" size="mini" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机OS版本号">
          <el-input type="text" v-model="form.osVersion" size="mini" clearable></el-input>
        </el-form-item>
        <el-form-item label="app版本">
          <el-input type="text" v-model="form.appVersion" size="mini" clearable></el-input>
        </el-form-item>
        <el-form-item label="日期*" >
          <el-date-picker
              clearable
              size="mini"
              v-model="date"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="searchData" size="mini"><i class="el-icon-search"></i> 查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table">
      <el-table
          :data="logList"
          border
          style="width: 100%">
        <el-table-column
            prop="_id"
            label="ID"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="uid"
            label="用户ID"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="用户名称"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="deviceBrand"
            label="设备品牌"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="osName"
            label="系统名称"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="osVersion"
            label="手机OS版本"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="appVersion"
            label="app版本"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="filePath"
            label="日志文件"
            align="center"
        >
          <template v-slot="{row}">
            <el-link type="primary" @click="toView(row)">{{row.originalFilename}}</el-link>
          </template>
        </el-table-column>
        <el-table-column
            label="记录时间"
            prop="createTime"
            align="center"
            :formatter="row=>returnTime(row.createTime)"
        >
        </el-table-column>
      </el-table>
      <div class="pagination" v-if="logCount>0">
        <el-pagination
            background
            layout="prev, pager, next"
            :total="logCount"
            @current-change="currentChange"
            :current-page="form.page"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import dateFun from "@/utils/dateFun"
import {abnormalLogList, getAppList} from "@/api/base";

export default {
  name: "ServiceLog",
  data() {
    return {
      form: {
        page: 1,
        limit: 10
      },
      date:'',
      eventList: {},
      userInfo: {},
      tableData: [],
      logList: [],
      logCount: 0,
      logUrl:''
    }
  },
  mounted() {
    const userInfo = localStorage.getItem('userInfo')
    this.userInfo = JSON.parse(userInfo)
    this.getAppList()
    this.search()
  },
  methods: {
    getAppList() {
      getAppList().then(res => {
        this.tableData = res.data
      })
    },
    currentChange(current) {
      this.form.page = current
      this.search()
    },
    searchData() {
      this.$set(this.form, 'page', 1)
      this.search()
    },
    search() {
      if(this.date){
        this.form.logStartTime = this.date[0]
        this.form.logEndTime = this.date[1]
      }else{
        delete  this.form.logStartTime
        delete  this.form.logEndTime
      }
      console.log(this.form)
      const queryParams = {}
      for (const key in this.form) {
        if(this.form[key]){
          queryParams[key] = this.form[key]
        }
      }
      abnormalLogList(queryParams).then(res => {
        this.logList = res.data
        this.logCount = res.count
      })
    },
    returnTime(time) {
      return dateFun.getCurrentDate(true, ['-', ':'], time)
    },
    getTimeStrByHour(time = null) {
      const today = time ? new Date(time) : new Date()
      const year = today.getFullYear()

      // 月份
      let month = today.getMonth() + 1
      if (month < 10) month = `0${month}`

      // 日期
      let day = today.getDate()
      if (day < 10) day = `0${day}`

      // 组装
      return `${year}${month}${day}`
    },
    toView(row){
      const fileHost = localStorage.getItem('ossBaseUrl')
      this.logUrl = fileHost + row.filePath
      window.open(this.logUrl, '_blank');
    }
  }
}
</script>

<style scoped>
.table {
  margin-top: 10px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
<style>
.el-tooltip__popper {
  max-width: 500px;
}
</style>
