<template>
    <div class="service-log">
        <div>
            <el-form inline>
                <el-form-item label="选择应用*" v-if="userInfo.username === 'admin'">
                    <el-select v-model="form.appId" placeholder="请选择应用">
                        <el-option v-for="item of tableData" :label="`${item.name}-${item._id}`" :key="item._id"
                                   :value="item._id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="来源*">
                    <el-select v-model="form.source" placeholder="请选择来源">
                        <el-option v-for="item of queryParams.source" :label="item._id" :key="item._id"
                                   :value="item._id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="等级">
                    <el-select v-model="form.level" placeholder="请选择等级">
                        <el-option v-for="item of queryParams.level" :label="item._id" :key="item._id"
                                   :value="item._id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="分类*">
                    <el-select v-model="form.classify" placeholder="请选择分类">
                        <el-option v-for="item of queryParams.classify" :label="item._id" :key="item._id"
                                   :value="item._id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="日期*">
                    <el-date-picker
                            v-model="form.date"
                            type="date"
                            format="yyyy-MM-dd"
                            placeholder="选择日志日期"
                            value-format="yyyyMMdd"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="success" @click="searchData"><i class="el-icon-search"></i> 查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="table">
            <el-table
                    :data="logList"
                    border
                    style="width: 100%">
                <el-table-column
                        prop="_id"
                        label="ID"
                        width="230"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        prop="level"
                        label="日志等级"
                        width="100"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        label="日志时间"
                        prop="createTime"
                        width="230"
                        align="center"
                        :formatter="row=>returnTime(row.createTime)"
                >
                </el-table-column>
                <el-table-column
                        label="日志内容"
                        prop="info"
                        align="center"
                >
                    <template slot-scope="{row}">
                        <el-tooltip class="item" effect="dark" :content="JSON.stringify(row.info)" placement="top">
                            <span style="max-width: 100%; display: block; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">{{ JSON.stringify(row.info) }}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination" v-if="logCount>0">
                <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="logCount"
                        @current-change="currentChange"
                        :current-page="form.page"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import dateFun from "@/utils/dateFun"
import {appLogParams, getAppList, getLogList} from "@/api/base";

export default {
    name: "ServiceLog",
    data() {
        return {
            form: {
                page: 1,
                limit: 10
            },
            queryParams: {},
            userInfo: {},
            tableData: [],
            logList: [],
            logCount: 0,
        }
    },
    mounted() {
        const userInfo = localStorage.getItem('userInfo')
        this.userInfo = JSON.parse(userInfo)
        this.getQueryParams()
        this.$set(this.form, 'date', this.getTimeStrByHour())
        this.getList()
    },
    methods: {
        getList() {
          getAppList().then(res => {
                this.tableData = res.data
            })
        },
        getQueryParams() {
          appLogParams().then(res => {
                this.queryParams = res.data
            })
        },
        currentChange(current) {
            this.form.page = current
            this.search()
        },
        searchData() {
            this.$set(this.form, 'page', 1)
            this.search()
        },
        search() {
            if (!this.form.source) {
                this.$message({
                    message: "请选择日志来源",
                    type: "warning"
                })
                return
            }
            if (!this.form.classify) {
                this.$message({
                    message: "请选择日志分类",
                    type: "warning"
                })
                return
            }
            if (!this.form.date) {
                this.$message({
                    message: "请选择日志日期",
                    type: "warning"
                })
                return
            }
          getLogList(this.form).then(res => {
                if (res.code === 0) {
                    this.logList = res.data
                    this.logCount = res.count
                }
            })
        },
        returnTime(time) {
            return dateFun.getCurrentDate(true, ['-', ':'], time)
        },
        getTimeStrByHour(time = null) {
            const today = time ? new Date(time) : new Date()
            const year = today.getFullYear()

            // 月份
            let month = today.getMonth() + 1
            if (month < 10) month = `0${month}`

            // 日期
            let day = today.getDate()
            if (day < 10) day = `0${day}`

            // 组装
            return `${year}${month}${day}`
        }
    }
}
</script>

<style scoped>
.table {
    margin-top: 10px;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
</style>
