<template>
  <div class="home">
    <div class="menu">
      <div class="web-title">数据分析</div>
      <el-menu
          default-active="/appManagement"
          class="el-menu-vertical-demo"
          @select="menuSelect"
      >
        <el-menu-item index="/appManagement" v-if="userInfo.username === 'admin'">
          <i class="el-icon-goods"></i>
          <span>应用管理</span>
        </el-menu-item>
        <el-menu-item index="/appManagementUser" v-if="userInfo.username === 'admin'">
          <i class="el-icon-goods"></i>
          <span>应用管理员</span>
        </el-menu-item>
        <el-menu-item index="/userEvents">
          <i class="el-icon-postcard"></i>
          <span slot="title">用户端事件管理</span>
        </el-menu-item>
        <el-menu-item index="/serviceLog">
          <i class="el-icon-tickets"></i>
          <span slot="title">后台日志</span>
        </el-menu-item>
        <el-menu-item index="/events">
          <i class="el-icon-tickets"></i>
          <span slot="title">用户端数据</span>
        </el-menu-item>
        <el-menu-item index="/abnormalLog">
          <i class="el-icon-tickets"></i>
          <span slot="title">客户端异常日志</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="right">
      <div class="header">
        <el-dropdown trigger="click" @command="commandClick">
          <span class="el-dropdown-link">
            {{userInfo.username||'未知用户'}}({{userInfo.appId||'未知'}})<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="content">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      userInfo:{}
    }
  },
  mounted() {
    const userInfo = localStorage.getItem('userInfo')
    this.userInfo = JSON.parse(userInfo)
    if(this.userInfo.username !== 'admin'){
      this.$router.push('/userEvents')
    }
  },
  methods: {
    menuSelect(index){
     this.$router.push(`${index}`)
    },
    commandClick(){
      this.$router.push('/')
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.menu {
  width: 220px;
  flex: 0 0 220px;
  height: calc(100% - 50px);
  .web-title{
    height: 50px;
    border-bottom: 1px solid #e6e6e6;
    border-right: 1px solid #035151;
    box-sizing: border-box;
    line-height: 50px;
    font-size: 26px;
    font-weight: bold;
    padding-left: 30px;
    background: #05696d;
    color: #fff;
  }
  .el-menu-vertical-demo {
    height: 100%;
  }
}

.right {
  flex: 1;
}

.header {
  height: 50px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  box-sizing: border-box;
  background: #05696d;
  .el-dropdown-link{
    color: #fff;
  }
}
.content{
  max-height: calc(100% - 50px);
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}
</style>
